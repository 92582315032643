.header {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  padding-top: 5px;
  max-width: 600px;
}

.header-logo {
  height: 40px;
  max-width: 140px;
  margin-right: 24px;
}
@media screen and (min-width: 631px) { .header-logo { height:47px;  } }

.header-link {
  cursor: pointer;
  padding-top: 10px;
}

.header-right {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  height: 72px;
}

.header-location-search-container {
  box-shadow: var(--primary-box-shadow);
  height: 54px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: var(--primary-bg);
  border: 1px solid var(--border-white);
}

.location-search-separator {
  height: 20px;
  border: 0.5px solid var(--gray);
}

.location-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  gap: 8px;
  width: 122px;
}

.location-icon-name {
  display: flex;
  color: var(--zomato-gray);
}

.location-icon {
  margin-right: 8px;
  font-size: 20px;
  color: var(--zomato-red);
}

.caret-down-icon {
  color: var(--gray);
  padding-top: 1px;
}

.header-searchBar {
  display: flex;
  position: relative;
}

.search-icon {
  font-size: 20px;
  color: var(--zomato-red);
  margin: 0px 12px;
  cursor: pointer;
}
.cross-icon {
  font-size: 15px;
  color: var(--zomato-red);
  margin: 0px 12px;
  cursor: pointer;
  
}
.wrapper-cross-icon {
  position: absolute;
  right: 0;
}

.search-input {
  border: none;
  min-width: 210px;
  font-size: 14px;
  outline: none;
  
}
.profile-wrapper {
  display: flex;
  justify-content: space-between;
  width: 15%;
  align-items: center;
}
.gly-rotate-45 {
  filter: progid: DXImageTransform.Microsoft.BasicImage(rotation=0.5);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  display: inline-block;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.rotate {
  animation: rotation 2s infinite linear;
}

.header-profile-image {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.header-username {
  font-size: 16px;
  font-weight: 600;
}

.profile-options-icon {
  font-size: 20px;
}
.profile-avatar-image {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  max-height: 130px;
  background: linear-gradient(-30deg,#ef8714 0,#eb7d23 20%,#9b3e9b 80%,#5847a3 100%);
  border-radius: 200px;
  padding: 4px;
}
@media screen and (max-width: 631px) { .profile-avatar-image { max-height: 110px; max-width: 110px;  } }

.profile-avatar {
  width: 130px;
}
.profile-text {
  margin-left: 2px;
  margin-top: 4px;
}
.profile-username-wrapper{
  display: flex;
  align-items: center;
  height: 50px;
}

.profile-username {
  font-size: 30px;
  font-weight: 700;
  line-height: 30px;
  word-break: break-word;
}

.profile-info {
  margin-left: 15px;
}

.profile-channel-icons {
  margin-top: 3px;
}
.profile-channel-icons > div > a {
  margin-right: 8px;
}
.profile-channel-icons > div > a > svg {
  width: 32px;
  height: 32px;
}