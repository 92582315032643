@import url(https://fonts.googleapis.com/css2?family=Nunito&display=swap);
.absolute-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.max-width {
  max-width: 1100px;
  margin: 0px auto;
  padding-left: 5px;
}

.cur-po {
  cursor: pointer;
}

.collection-title {
  font-size: 30px;
  color: var(--heading-text);
  margin-bottom: 32px;
  font-weight: 500;
}

.slick-next:before,
.slick-prev:before {
  color: var(--gray);
}

#root {
  --primary-box-shadow: rgb(28 28 28 / 8%) 0px 2px 8px;
  --primary-bg: rgb(255, 255, 255);
  --gray: rgb(207, 207, 207);
  --zomato-red: #ff7e8b;
  --zomato-gray: #828282;
  --border-white: rgb(232, 232, 232);
  --inactive-color: #f8f8f8;
  --inactive-text: #696969;
  --filter-text: #9c9c9c;
  --secondary-bg: #f8f8f8;
  --heading-text: #1c1c1c;
  --collection-shadow: rgb(0 0 0 / 8%) 0px 3px 12px;
  --tag-red: #d92662;
  --tag-blue: #256fef;
  --tag-green: #24963f;
  --subtitle-text: #363636;
  --bg-gradient: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.05) 50%,
    rgba(0, 0, 0, 0.05) 85%
  );
}

body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.footer {
	
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  padding-top: 5px;
  max-width: 600px;
}

.header-logo {
  height: 40px;
  max-width: 140px;
  margin-right: 24px;
}
@media screen and (min-width: 631px) { .header-logo { height:47px;  } }

.header-link {
  cursor: pointer;
  padding-top: 10px;
}

.header-right {
  display: flex;
  flex: 1 1;
  justify-content: space-between;
  align-items: center;
  height: 72px;
}

.header-location-search-container {
  box-shadow: var(--primary-box-shadow);
  height: 54px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: var(--primary-bg);
  border: 1px solid var(--border-white);
}

.location-search-separator {
  height: 20px;
  border: 0.5px solid var(--gray);
}

.location-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  grid-gap: 8px;
  gap: 8px;
  width: 122px;
}

.location-icon-name {
  display: flex;
  color: var(--zomato-gray);
}

.location-icon {
  margin-right: 8px;
  font-size: 20px;
  color: var(--zomato-red);
}

.caret-down-icon {
  color: var(--gray);
  padding-top: 1px;
}

.header-searchBar {
  display: flex;
  position: relative;
}

.search-icon {
  font-size: 20px;
  color: var(--zomato-red);
  margin: 0px 12px;
  cursor: pointer;
}
.cross-icon {
  font-size: 15px;
  color: var(--zomato-red);
  margin: 0px 12px;
  cursor: pointer;
  
}
.wrapper-cross-icon {
  position: absolute;
  right: 0;
}

.search-input {
  border: none;
  min-width: 210px;
  font-size: 14px;
  outline: none;
  
}
.profile-wrapper {
  display: flex;
  justify-content: space-between;
  width: 15%;
  align-items: center;
}
.gly-rotate-45 {
  filter: progid: DXImageTransform.Microsoft.BasicImage(rotation=0.5);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  display: inline-block;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

.rotate {
  -webkit-animation: rotation 2s infinite linear;
          animation: rotation 2s infinite linear;
}

.header-profile-image {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.header-username {
  font-size: 16px;
  font-weight: 600;
}

.profile-options-icon {
  font-size: 20px;
}
.profile-avatar-image {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  max-height: 130px;
  background: linear-gradient(-30deg,#ef8714 0,#eb7d23 20%,#9b3e9b 80%,#5847a3 100%);
  border-radius: 200px;
  padding: 4px;
}
@media screen and (max-width: 631px) { .profile-avatar-image { max-height: 110px; max-width: 110px;  } }

.profile-avatar {
  width: 130px;
}
.profile-text {
  margin-left: 2px;
  margin-top: 4px;
}
.profile-username-wrapper{
  display: flex;
  align-items: center;
  height: 50px;
}

.profile-username {
  font-size: 30px;
  font-weight: 700;
  line-height: 30px;
  word-break: break-word;
}

.profile-info {
  margin-left: 15px;
}

.profile-channel-icons {
  margin-top: 3px;
}
.profile-channel-icons > div > a {
  margin-right: 8px;
}
.profile-channel-icons > div > a > svg {
  width: 32px;
  height: 32px;
}
.explore-card {
  margin: 12px 0px 24px 0px;
  padding: 10px;
  border-radius: 15px;
  border: 1px solid transparent;
}
.explore-card-first {
  margin-top: 12px;
}
.explore-card-cover {
  height: 248px;
  width: 328px;
  border-radius: 15px;
  position: relative;
}

.explore-card-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.delivery-time {
  position: absolute;
  right: 10px;
  bottom: 10px;
  opacity: 0.8;
  background-color: var(--primary-bg);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  font-weight: 600;
  font-size: 12px;
  padding: 2px 5px 3px;
  border-radius: 4px;
}

.pro-off {
  position: absolute;
  left: 0px;
  bottom: 40px;
  background-color: var(--tag-red);
  color: var(--primary-bg);
  font-size: 14px;
  padding: 0px 6px;
  border-radius: 0px 2px 2px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 18px;
  font-weight: 500;
}

.discount {
  position: absolute;
  background-color: var(--tag-blue);
  color: var(--primary-bg);
  font-weight: 500;
  font-size: 14px;
  padding: 0px 6px;
  border-radius: 0px 2px 2px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 18px;
  left: 0px;
  bottom: 14px;
}
.gold-off {
  position: absolute;
  background-color: var(--tag-red);
  color: var(--primary-bg);
  font-weight: 500;
  font-size: 14px;
  padding: 0px 6px;
  border-radius: 0px 2px 2px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 18px;
  left: 0px;
  bottom: 14px;
}
.res-row {
  display: flex;
  justify-content: space-between;
  padding: 8px 0px 6px;
}

.res-name {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  font-weight: 600;
  color: var(--heading-text);
}

.res-rating {
  padding: 0px 8px;
  font-size: 13px;
  font-weight: 600;
  color: var(--primary-bg);
  background-color: var(--tag-green);
  border-radius: 6px;
  height: 24px;
}

.res-rating i {
  font-size: 10px;
  margin-left: 2px;
}

.res-cuisine {
  max-width: 56%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
}

.res-cuisine-tag {
  margin-right: 4px;
  color: var(--inactive-text);
}

.res-price {
  font-size: 14px;
  color: var(--inactive-text);
}

.explore-bottom {
  display: flex;
  align-items: center;
  max-width: 100%;
}

.res-bottom-text {
  font-size: 12px;
  color: var(--inactive-text);
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 8px;
}

.card-separator {
  height: 1px;
  margin: 10px 0px 5px;
  background-color: var(--border-white);
}

.explore-card:hover {
  background-color: var(--primary-bg);
  border: 1px solid var(--border-white);
}



.white-box {
	height: "100%";
	width: "100%";
	background-color: "#ffffff";
	border-radius: "9px";
	margin: "15px";
	padding-top: "15px";
	padding-left: "10px";
	z-index: 999;
}

.post-action-group {
	justify-content: center;
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 25px;
}

.image-zoom-cover-container {
	position: fixed ;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background-color: rgba(255,255,255,0.5);
	align-items: center;
	justify-content: center;
	display: flex;
}
.image-zoom-cover {
	position: relative;
	display: flex;
	width: 400px;
}
@media screen and (max-width: 400px) { .image-zoom-cover { width: 95%; } }


.post-cover {
  height: 425px;
  border-radius: 6px;
  position: relative;
  z-index: 2;
  width: 98%;
  cursor: pointer;
  margin-bottom: 5px;
}

.image-mini-cover {
	position: absolute;
	padding-right: 1px;
	right: 0;
	bottom: 0;
	width: 33%;
	border-radius: 6px;
}
.explore-section {
  padding-top: 5px;
}

.explore-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.posts-grid {
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  padding-bottom: 15px;
  flex-wrap: wrap;
  justify-items: center;
}
.tiktok-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(190px, -webkit-max-content));
  grid-template-columns: repeat(auto-fill, minmax(190px, max-content));
  grid-gap: 5px;
  padding: initial;
  justify-content: center;
}

@media screen and (max-width: 400px) { .tiktok-grid { grid-template-columns: repeat(auto-fill, minmax(150px, -webkit-max-content)); grid-template-columns: repeat(auto-fill, minmax(150px, max-content)); } }
@media screen and (min-width: 600px) { .tiktok-grid { grid-template-columns: repeat(auto-fill, minmax(230px, -webkit-max-content)); grid-template-columns: repeat(auto-fill, minmax(230px, max-content)); } }

.place-cover {
  height: 310px;
  border-radius: 6px;
  position: relative;
  z-index: 2;
  width: 98%;
  cursor: pointer;
  margin-bottom: 5px;
}

.place-card-title {
  position: absolute;
  left: 16px;
  bottom: 30px;
  font-size: 18px;
  color: var(--primary-bg);
  display: flex;
  grid-gap: 5px;
  gap: 5px;
}
.place-card-subtitle {
  display: flex;
  color: var(--primary-bg);
  position: absolute;
  left: 18px;
  bottom: 10px;
  font-size: 14px;
  grid-gap: 6px;
  gap: 6px;
}

.place-distance {
  margin-right: 5px;
  padding-top: 2px;
}
.icon-distance {
  font-size: 14px;
}
.place-search-box {
  display: flex;
  margin-bottom: 16px;
  margin-top: 5px;
  justify-content: center;
}

.tab-options {
  box-shadow: var(--border-white) 0px -0.5px 0px inset;
}

.options-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.tab-item {
  margin-right: 24px;
  padding: 12px 12px 20px 12px;
}

.tab-name {
  color: var(--inactive-text);
  font-size: 20px;
  font-weight: 500;
  margin-left: 10px;
}
.tab-image-container {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: var(--inactive-color);
}
.tab-image {
  height: 30px;
  width: 30px;
}

.tab-group {

}

.active-tab {
  border-bottom: 2px solid var(--zomato-red);
}

.active-tab .tab-name {
  color: var(--zomato-red);
}

.tabs-wrapper {
  display: flex;
}

.filter-item {
  padding: 8px;
  display: flex;
  background-color: var(--primary-bg);
  color: var(--filter-text);
  border: 1px solid var(--gray);
  border-radius: 8px;
  margin: 10px 10px 10px 0px;
  font-size: 14px;
}

.filter-name {
  margin-left: 5px;
}

.filters {
  padding: 16px 0px;
  display: flex;
  flex-wrap: wrap;
}


.explore-card {
  margin: 12px 0px 24px 0px;
  padding: 10px;
  border-radius: 15px;
  border: 1px solid transparent;
}
.explore-card-first {
  margin-top: 12px;
}
.explore-card-cover {
  height: 248px;
  width: 328px;
  border-radius: 15px;
  position: relative;
}

.explore-card-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.delivery-time {
  position: absolute;
  right: 10px;
  bottom: 10px;
  opacity: 0.8;
  background-color: var(--primary-bg);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  font-weight: 600;
  font-size: 12px;
  padding: 2px 5px 3px;
  border-radius: 4px;
}

.pro-off {
  position: absolute;
  left: 0px;
  bottom: 40px;
  background-color: var(--tag-red);
  color: var(--primary-bg);
  font-size: 14px;
  padding: 0px 6px;
  border-radius: 0px 2px 2px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 18px;
  font-weight: 500;
}

.discount {
  position: absolute;
  background-color: var(--tag-blue);
  color: var(--primary-bg);
  font-weight: 500;
  font-size: 14px;
  padding: 0px 6px;
  border-radius: 0px 2px 2px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 18px;
  left: 0px;
  bottom: 14px;
}
.gold-off {
  position: absolute;
  background-color: var(--tag-red);
  color: var(--primary-bg);
  font-weight: 500;
  font-size: 14px;
  padding: 0px 6px;
  border-radius: 0px 2px 2px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 18px;
  left: 0px;
  bottom: 14px;
}
.res-row {
  display: flex;
  justify-content: space-between;
  padding: 8px 0px 6px;
}

.res-name {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  font-weight: 600;
  color: var(--heading-text);
}

.res-rating {
  padding: 0px 8px;
  font-size: 13px;
  font-weight: 600;
  color: var(--primary-bg);
  background-color: var(--tag-green);
  border-radius: 6px;
  height: 24px;
}

.res-rating i {
  font-size: 10px;
  margin-left: 2px;
}

.res-cuisine {
  max-width: 56%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
}

.res-cuisine-tag {
  margin-right: 4px;
  color: var(--inactive-text);
}

.res-price {
  font-size: 14px;
  color: var(--inactive-text);
}

.explore-bottom {
  display: flex;
  align-items: center;
  max-width: 100%;
}

.res-bottom-text {
  font-size: 12px;
  color: var(--inactive-text);
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 8px;
}

.card-separator {
  height: 1px;
  margin: 10px 0px 5px;
  background-color: var(--border-white);
}

.explore-card:hover {
  background-color: var(--primary-bg);
  border: 1px solid var(--border-white);
}

.explore-section {
  padding-top: 5px;
}

.explore-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.posts-grid {
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  padding-bottom: 15px;
  flex-wrap: wrap;
  justify-items: center;
}
.tiktok-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(190px, -webkit-max-content));
  grid-template-columns: repeat(auto-fill, minmax(190px, max-content));
  grid-gap: 5px;
  padding: initial;
  justify-content: center;
}

@media screen and (max-width: 400px) { .tiktok-grid { grid-template-columns: repeat(auto-fill, minmax(150px, -webkit-max-content)); grid-template-columns: repeat(auto-fill, minmax(150px, max-content)); } }
@media screen and (min-width: 600px) { .tiktok-grid { grid-template-columns: repeat(auto-fill, minmax(230px, -webkit-max-content)); grid-template-columns: repeat(auto-fill, minmax(230px, max-content)); } }

.place-cover {
  height: 310px;
  max-width: 225px;
  border-radius: 6px;
  position: relative;
  z-index: 2;
  width: 98%;
  cursor: pointer;
  margin-bottom: 5px;
}

.shopping-card-title {
  position: absolute;
  left: 7px;
  bottom: 10px;
  font-size: 18px;
  color: var(--primary-bg);
  display: flex;
  grid-gap: 5px;
  gap: 5px;
}
.place-card-subtitle {
  display: flex;
  color: var(--primary-bg);
  position: absolute;
  left: 18px;
  bottom: 10px;
  font-size: 14px;
  grid-gap: 6px;
  gap: 6px;
}

.place-distance {
  margin-right: 5px;
  padding-top: 2px;
}
.icon-distance {
  font-size: 14px;
}
.place-search-box {
  display: flex;
  margin-bottom: 16px;
  margin-top: 5px;
  justify-content: center;
}
.collection-wrapper {
  background-color: var(--secondary-bg);
}

.collection {
  padding: 15px 0px 40px;
}

.collection-subtitle-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.collection-subtitle-text {
  color: var(--subtitle-text);
  font-size: 18px;
}

.collection-location {
  color: var(--zomato-red);
  display: flex;
}

.collection-cover {
  height: 320px;
  width: 255px;
  margin-right: 20px;
  margin-bottom: 15px;
  border-radius: 6px;
  position: relative;
  z-index: 2;
}

.gradient-bg {
  position: absolute;
  height: 100%;
  width: 100%;
  background: var(--bg-gradient);
  top: 0;
  left: 0;
  border-radius: 6px;
}
.collection-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.collection-card-title {
  position: absolute;
  left: 16px;
  bottom: 30px;
  font-size: 18px;
  color: var(--primary-bg);
}

.collection-card-subtitle {
  display: flex;
  color: var(--primary-bg);
  position: absolute;
  left: 18px;
  bottom: 10px;
  font-size: 14px;
}



.group-header {
	
}
