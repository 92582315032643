

.white-box {
	height: "100%";
	width: "100%";
	background-color: "#ffffff";
	border-radius: "9px";
	margin: "15px";
	padding-top: "15px";
	padding-left: "10px";
	z-index: 999;
}

.post-action-group {
	justify-content: center;
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 25px;
}

.image-zoom-cover-container {
	position: fixed ;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background-color: rgba(255,255,255,0.5);
	align-items: center;
	justify-content: center;
	display: flex;
}
.image-zoom-cover {
	position: relative;
	display: flex;
	width: 400px;
}
@media screen and (max-width: 400px) { .image-zoom-cover { width: 95%; } }


.post-cover {
  height: 425px;
  border-radius: 6px;
  position: relative;
  z-index: 2;
  width: 98%;
  cursor: pointer;
  margin-bottom: 5px;
}

.image-mini-cover {
	position: absolute;
	padding-right: 1px;
	right: 0;
	bottom: 0;
	width: 33%;
	border-radius: 6px;
}