.tab-options {
  box-shadow: var(--border-white) 0px -0.5px 0px inset;
}

.options-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.tab-item {
  margin-right: 24px;
  padding: 12px 12px 20px 12px;
}

.tab-name {
  color: var(--inactive-text);
  font-size: 20px;
  font-weight: 500;
  margin-left: 10px;
}
.tab-image-container {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: var(--inactive-color);
}
.tab-image {
  height: 30px;
  width: 30px;
}

.tab-group {

}

.active-tab {
  border-bottom: 2px solid var(--zomato-red);
}

.active-tab .tab-name {
  color: var(--zomato-red);
}

.tabs-wrapper {
  display: flex;
}
